






function collapsedSidebar(lang) {
    setTimeout(function(){
        if (lang === 'rtl') {
            if ($(window).width() <= 765) {
                if ($("app-root").hasClass("aside-collapsed")) {
                    $("#margin-dir,#margin-dir-footer").css("margin-right", "0px").css("margin-left", "0");
                } else {
                    $("#margin-dir,#margin-dir-footer").css("margin-right", "0px").css("margin-left", "0");
                }
    
                if ($("app-root").hasClass("layout-fixed aside-collapsed")) {
                    $("#margin-dir-aside-container").css("margin-right", "-140px").css("margin-left", "0");
                } else if ($("app-root").hasClass("aside-collapsed aside-toggled")) {
                    $("#margin-dir-aside-container").css("margin-right", "-70px").css("margin-left", "0");
                }
            } else {
                if ($("app-root").hasClass("aside-collapsed")) {
                    $("#margin-dir,#margin-dir-footer").css("margin-right", "70px").css("margin-left", "0");
                } else {
                    $("#margin-dir,#margin-dir-footer").css("margin-right", "220px").css("margin-left", "0");
                }
            }
    
        } else {
            if ($(window).width() <= 765) {
                if ($("app-root").hasClass("aside-collapsed")) {
                    $("#margin-dir,#margin-dir-footer").css("margin-left", "0px").css("margin-right", "0");
                } else {
                    $("#margin-dir,#margin-dir-footer").css("margin-left", "0px").css("margin-right", "0");
                }
            } else {
                if ($("app-root").hasClass("aside-collapsed")) {
                    $("#margin-dir,#margin-dir-footer").css("margin-left", "70px").css("margin-right", "0");
                } else {
                    $("#margin-dir,#margin-dir-footer").css("margin-left", "220px").css("margin-right", "0");
                }
            }
        }
    },300);
}






function collapsedSidebarToggle() {
    if ($("div").hasClass("rtl")) {
        if ($(window).width() <= 765) {
            if (!$("app-root").hasClass("aside-collapsed")) {
                $("#margin-dir,#margin-dir-footer").css("margin-right", "0px").css("margin-left", "0");
            } else if ($("app-root").hasClass("aside-collapsed aside-toggled")) {
                $("#margin-dir-aside-container").css("margin-right", "-140px").css("margin-left", "0");
                $("#margin-dir,#margin-dir-footer").css("margin-right", "0px").css("margin-left", "0");
            }
            else {
                $("#margin-dir-aside-container").css("margin-right", "0px").css("margin-left", "0");
                $("#margin-dir,#margin-dir-footer").css("margin-right", "140px").css("margin-left", "-70px");
            }
        } else {
            if (!$("app-root").hasClass("aside-collapsed")) {
                $("#margin-dir,#margin-dir-footer").css("margin-right", "70px").css("margin-left", "0");
            } else {
                $("#margin-dir,#margin-dir-footer").css("margin-right", "220px").css("margin-left", "0");
            }
        }
    }
    else {
        if ($(window).width() <= 765) {
            if (!$("app-root").hasClass("aside-collapsed")) {
                $("#margin-dir,#margin-dir-footer").css("margin-left", "0px").css("margin-right", "0");
            } else {
                $("#margin-dir,#margin-dir-footer").css("margin-left", "0px").css("margin-right", "0");
            }
        } else {
            if (!$("app-root").hasClass("aside-collapsed")) {
                $("#margin-dir,#margin-dir-footer").css("margin-left", "70px").css("margin-right", "0");
            } else {
                $("#margin-dir,#margin-dir-footer").css("margin-left", "220px").css("margin-right", "0");
            }
        }
    }
}

